/* App.css */
.main-box {
    width: 100%;
    height: 90vh;
    overflow-x: auto;
}
.meetings-page {
    max-width: 1000px;
    margin: 0 auto;
    font-size: 16px;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.new-meeting-btn {
    padding: 15px;
    background-color: #001E51;
    color: #fff;
    border: none;
    cursor: pointer;
    font-family: 'Gothic A1';
    border-radius: 10px;
}
.title{
    font-family: Gothic A1;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    color: #001E51;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

th {
    background-color: #0077B6;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
    font-family: Gothic A1;
}

tbody tr:hover {
    background-color: #f3f3f3;
}

mbutton {
    padding: 8px 12px;
    margin-right: 5px;
    cursor: pointer;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.action-menu {
    position: relative;
    display: inline-block;
}

.action-menu span {
    cursor: pointer;
    display: block;
    padding: 12px;
}

.action-menu span:hover {
    background-color: #f5f5f5;
}
.meetings-header-title{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.ongoing-meet-section{
    margin-bottom: 40px;
    margin-top: 40px;
}


/* Media Query for Tablets and Smaller Desktops */
@media (max-width: 768px) {
    .meetings-page {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .new-meeting-btn {
        padding: 12px; /* Adjust padding for the button */
    }

    th, td {
        padding: 10px; /* Adjust padding for table cells */
    }

    button {
        padding: 6px 10px; /* Adjust padding for buttons */
    }

    .action-menu span {
        padding: 10px; /* Adjust padding for action menu items */
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .meetings-page {
        font-size: 12px; /* Further reduce font size for smaller screens */
    }

    .new-meeting-btn {
        padding: 10px; /* Further adjust padding for the button */
    }

    th, td {
        padding: 8px; /* Further adjust padding for table cells */
    }

    button {
        padding: 5px 8px; /* Further adjust padding for buttons */
    }

    .action-menu span {
        padding: 8px; /* Further adjust padding for action menu items */
    }
}
