.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "Gothic A1", sans-serif;
    background-color: #F3F3F3;
  }
  
  .Login-body {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .Login-image {
    width: 50%;
    max-width: 500px;
  }
  .form-section {
    display: flex;
    flex-direction: column;
    width: 100%; /* Adjusted width for better responsiveness */
    padding: 20px;
    text-align: center;
    align-items: center;
  }
  
  .input-container h3 {
    color: #637A9F;
    font-size: 2.5em;
    font-weight: normal;
  }
  
.rounded-input {
    width: 90%; /* Adjusted width for better responsiveness */
    height: 40px;
    padding: 1em;
    margin: 1em 0;
    border: 1px solid #ccc;
    border-radius: 15px;
    border-color: #A1B5D8;
    font-size: 1em;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"]::placeholder {
    text-indent: 1em;
  }
  
  .login-button,
  .login-know-button {
    padding: 1em 2em;
    border: 1px solid;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: bold;
    width: 100%; /* Adjusted width for better responsiveness */
    height: 3em;
  }
  
  .login-button {
    background-color: #A0BC95;
    color: #ffffff;
    margin-top: 1.5em;
  }
  
  .login-know-button {
    background-color: #ffffff;
    color: #A0BC95;
  }
  
  .form-section h6 {
    color: #0077B6;
    text-align: left;
    float: left;
  }
  
  .Login-know-more {
    margin-top: 2em;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .Login-know-more h5 {
    color: #999999;
  }
  
  .form-section .Login-Forget-Password {
    width: 100%;
    display: flex;
    justify-content: left;
  }
  
  @media (min-width: 0px) {
    /* Adjust styles for larger screens if needed */
    .Login-image {
      width: 60%;
    }
  
    .form-section {
      width: 40%;
      max-width: 500px; /* Set a maximum width for larger screens */
    }
  }
  