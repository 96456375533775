/* IframeDemo.css */

.room-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Adjust margin as needed */
}

.input-box-container {
  margin-bottom: 20px; /* Adjust margin as needed */
}

.iframe-box-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 10px; /* Adjust gap as needed */
}

.iframe-box {
  width: 100%; /* Adjust width as needed */
  height: 100%; /* Adjust height as needed */
  max-width: calc(50% - 10px); /* Maximum width of 50% of the container width with gap included */
  max-height: calc(50vh - 10px); /* Maximum height of 50% of the viewport height with gap included */
}

.ibutton {
  margin-top: 10px; /* Adjust margin as needed */
}
